import { EditorContextHelper } from '@magnolia/template-annotations';

/**
 * @deprecated Since version 1.2.0. Use inIframe with params check instead.
 */
export function inEditorAsync() {
  return EditorContextHelper.inEditorAsync();
}

export function inEditor() {
  return EditorContextHelper.inEditor();
}

/**
 * @deprecated Since version 1.1.0. Use inEditorAsync instead.
 */
export function inEditorPreview() {
  return EditorContextHelper.inEditorPreview();
}

/**
 * @deprecated Since version 1.1.0. Use inEditorAsync instead.
 */
export function isRenderingAnnotation() {
  return EditorContextHelper.isRenderingAnnotation();
}

export function inIframe() {
  return EditorContextHelper.inIframe();
}

export function isSameOrigin() {
  return EditorContextHelper.isSameOrigin();
}

export function refresh(delayInMilliseconds) {
  EditorContextHelper.refresh(delayInMilliseconds);
}

export function setServerUrl(url) {
  EditorContextHelper.setServerUrl(url);
}

export function isPageEditorReady() {
  return EditorContextHelper.isPageEditorReady();
}

export function onFrameReady() {
  EditorContextHelper.onFrameReady();
}

export function inPreviewAsVisitor() {
  return EditorContextHelper.inPreviewAsVisitor();
}

export function getMagnoliaContext(requestUrl, spaRootNodePath, languages) {
  return EditorContextHelper.getMagnoliaContext(requestUrl, spaRootNodePath, languages);
}

export default {
  inIframe,
  isSameOrigin,
  refresh,
  setServerUrl,
  isPageEditorReady,
  onFrameReady,
  inPreviewAsVisitor,
  inEditorAsync,
  inEditorPreview,
  isRenderingAnnotation,
  getMagnoliaContext
};
