/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { TemplateAnnotations } from '@magnolia/template-annotations';
import {
  EditorContext, EditorContextHelper, constants, ComponentHelper, PersonalizationService
} from '../../util';
import { EditableComment } from '../EditableComment';

export default class EditableComponent extends React.PureComponent {
  static propTypes = {
    content: PropTypes.object.isRequired,
    index: PropTypes.number
  };

  static defaultProps = {
    index: 0
  };

  constructor(props) {
    super(props);
    this.constants = constants;
  }

  static contextType = EditorContext;

  getAnnotation() {
    const { content } = this.props;
    if (!content) {
      return '';
    }
    const pageTemplateId = content[constants.TEMPLATE_ID_PROP];
    const { templateDefinitions, templateAnnotations } = this.context;
    if (templateDefinitions && templateDefinitions[pageTemplateId]) {
      return TemplateAnnotations.getComponentCommentString(content, templateDefinitions[pageTemplateId]);
    }
    if (templateAnnotations && templateAnnotations[content?.['@path']]) {
      return templateAnnotations[content?.['@path']];
    }
    return '';
  }

  onReady() {
    if (EditorContextHelper.inIframe()) {
      EditorContextHelper.refresh();
    }
  }

  render() {
    const { content, index } = this.props;
    const { componentMappings, fallbackComponent, templateAnnotations } = this.context;
    const variantContent = templateAnnotations ? PersonalizationService.getVariant(content, templateAnnotations) : content;
    const renderingContent = TemplateAnnotations.generateMissingAreas(variantContent, templateAnnotations);
    const component = ComponentHelper.getRenderedComponent(renderingContent, componentMappings, fallbackComponent, index);
    const annotation = this.getAnnotation();

    return (
      <>
        <EditableComment annotation={annotation} callback={this.onReady} />
        {component}
        {annotation
          ? <EditableComment annotation={this.constants.CLOSED_COMPONENT_COMMENT} />
          : <EditableComment />
        }
      </>
    );
  }
}
